
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
//import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-training-calendar',
  components: {
    Form,
    Field,
  },
  data() {
    return {
      moment: '' as any,
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        term_id: '',
        batch_id: '',
        total_terms: '',
      },
      count: 0,
      employee: {},
      details: {},
      weekplan: {},
      tranches_info: [],
      batchSingleInfodata: [],
      entityTypes: [],
      session: 0,
      industry: [],
      instituteList: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      termList: [],
      batchList: [],
      subDistricts: [],
      courseList: [],
      empDistricts: [],
      tableData: [],
      trainingCalendar: [] as any,
      trainingCalendarFinal: [] as any,
      tempobject: {
        checkStatus: true,
        date: '',
        day: '',
        start_time: '',
        end_time: '',

        daily_hour: '',
      },
      coursetermDetailsData: [],
      trainingCalendarSlice: [] as any,
      termDetailsData: [],
      loading: true,
      btnCheck: true,
      load: true,
      courseInfoData: false,
      showCourseNotice: false,
      componentKey: 0,
      showformSubmit: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.moment = moment;
    this.emitter.on('submitted-calendar', async () => {
      await this.calenderDetails();
      this.componentKey += 1;
    });
    await this.getTranches();
    await this.getEntityInfos();
  },
  methods: {
    CheckedSession(status, index) {
      console.log(' old calendar' + JSON.stringify(this.trainingCalendar));
      console.log(this.trainingCalendarFinal);

      if (!status) {
        this.session++;
        console.log('session barce' + this.session);
        // push next avialable date to trainingCalendar
        console.log(
          'final calendar e session' +
            JSON.stringify(this.trainingCalendarFinal[this.session])
        );
        if (this.trainingCalendarFinal[this.session]) {
          this.trainingCalendar.push(this.trainingCalendarFinal[this.session]);
        } else {
          this.trainingCalendar[index].checkStatus = true;
          // status = true;
          this.session--;

          Swal.fire({
            title: 'Error!',
            text: 'Batch End Date Exceed!',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        }
      } else {
        this.session--;
        console.log('session komce' + this.session);

        console.log('calendar pop hobe');
        if (this.trainingCalendarFinal[this.session]) {
          this.trainingCalendar.pop();
        }
      }
      // if (e.target.checked) {
      //   this.session--;
      //   this.calenderDetails();
      //   // this.emitter.on("submitted-calendar", async () => {
      //   //   this.componentKey += 1;
      //   // });
      // } else {
      //   this.session++;
      //   this.calenderDetails();

      //   // this.emitter.on("submitted-calendar", async () => {
      //   //   this.componentKey += 1;
      //   // });
      // }
    },
    AddRow(i) {
      console.log(i);
    },
    async formSubmit() {
      this.load = true;
      this.btnCheck = false;
      let formData = new FormData();
      for (var key in this.batch) {
        formData.set(key, this.batch[key]);
      }
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      formData.set('calendar_details', JSON.stringify(this.trainingCalendar));
      await ApiService.post(this.VUE_APP_TRAINING_CALENDAR_STORE_API, formData)
        .then((response) => {
          this.load = false;
          this.emitter.emit('submitted-calendar', true);
          //console.log(response);
          if (response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
            this.$router.push('/training/training-calendar');
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch((response) => {
          this.load = false;
          console.log(response);
        });
    },
    async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getEntityInfos() {
      this.loading = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async termInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_GET_BATCH_TERM_API +
          '?batch_info_id=' +
          this.batch.batch_id
      )
        .then((response) => {
          this.loading = false;
          this.termList = response.data.data;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          //console.log(response);
          this.batchList = response.data.data;
          this.courseInfoData = true;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchSingleInfo() {
      await ApiService.get(
        this.VUE_APP_GET_SINGLE_BATCH_API + `${this.batch.batch_id}`
      )
        .then((response) => {
          this.loading = false;
          //console.log(response);
          this.batchSingleInfodata = response.data.data;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseTermDetails() {
      this.load = false;
      await ApiService.get(
        this.VUE_APP_GET_COURSE_TERM_DETAILS_API +
          '?course_info_id=' +
          this.batch.course_id +
          '&term_no=' +
          this.batch.term_id
      )
        .then((response) => {
          // this.loading = false;
          //console.log(response);
          this.coursetermDetailsData = response.data.data;
          this.session = parseInt(response.data.data.term_session);
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async checkBatchEnrollment() {
      this.load = false;
      if (this.batch.term_id) {
        await ApiService.get(
          'trainee/enrollment_list?tranche_id=' +
            this.batch.tranche_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&training_institute_id=' +
            this.batch.institute_id +
            '&course_info_id=' +
            this.batch.course_id +
            '&batch_info_id=' +
            this.batch.batch_id +
            '&enrollment_status=1'
        )
          .then((response) => {
            if (response.data.data.length == 0) {
              Swal.fire({
                title: 'Warning!',
                html: 'Please enroll trainee first.',
                icon: 'warning',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            } else {
              this.termDetails();
            }
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      }
    },
    async termDetails() {
      this.load = false;
      if (this.batch.term_id) {
        this.loading = true;
        this.courseTermDetails();
        await ApiService.get(
          this.VUE_APP_GET_BATCH_TERM_DETAILS_API +
            '?batch_info_id=' +
            this.batch.batch_id +
            '&term_no=' +
            this.batch.term_id
        )
          .then((response) => {
            //console.log(response);
            this.termDetailsData = response.data.data;
            this.calenderDetails();
            // this.loading = false;
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select term first.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    async calenderDetails() {
      await ApiService.get(
        this.VUE_APP_TRAINING_CALENDAR_DETAILS_API +
          '?batch_info_id=' +
          this.batch.batch_id +
          '&term_no=' +
          this.batch.term_id +
          '&course_id=' +
          this.batch.course_id +
          '&entity_id=' +
          this.batch.entity_id +
          '&tranche_id=' +
          this.batch.tranche_id +
          '&institute_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          if (!response.data.data.length) {
            this.calenderGenerateDetails();
          } else {
            this.showformSubmit = false;
            this.trainingCalendar = [];
            for (let i = 0; i < response.data.data.length; i++) {
              this.tempobject.checkStatus = true;
              this.tempobject.date = moment(
                response.data.data[i].training_date
              ).format('DD-MM-YYYY');
              this.tempobject.day = response.data.data[i].training_day;
              this.tempobject.start_time = moment(
                response.data.data[i].start_time,
                'hh:mm'
              ).format('LT');
              this.tempobject.end_time = moment(
                response.data.data[i].end_time,
                'hh:mm'
              ).format('LT');

              this.tempobject.daily_hour =
                response.data.data[i].training_day_hour;
              this.trainingCalendar.push(
                JSON.parse(JSON.stringify(this.tempobject))
              );
            }
            this.loading = false;
            this.showCourseNotice = true;
          }
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async calenderGenerateDetails() {
      await ApiService.get(
        this.VUE_APP_TRAINING_CALENDAR_GENERATE_DETAILS_API +
          '?batch_info_id=' +
          this.batch.batch_id +
          '&term_no=' +
          this.batch.term_id
      )
        .then((response) => {
          this.trainingCalendarFinal = [];
          for (let i = 0; i < response.data.data.length; i++) {
            // this.tempobject.checkStatus=true;
            this.tempobject.date = moment(response.data.data[i].date).format(
              'DD-MM-YYYY'
            );
            this.tempobject.day = response.data.data[i].day;
            this.tempobject.start_time = moment(
              response.data.data[i].start_time,
              'hh:mm'
            ).format('LT');
            this.tempobject.end_time = moment(
              response.data.data[i].end_time,
              'hh:mm'
            ).format('LT');

            this.tempobject.daily_hour = response.data.data[i].daily_hour;
            this.trainingCalendarFinal.push(
              JSON.parse(JSON.stringify(this.tempobject))
            );
          }

          this.trainingCalendar = this.trainingCalendarFinal.slice(
            0,
            this.session
          );
          this.session--;
          this.componentKey += 1;

          this.showCourseNotice = true;
          this.loading = false;
          this.showformSubmit = true;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    courseNotice() {
      this.showCourseNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
